import { Modal, Tag } from "antd";
import React, { useEffect, useState } from "react";
import logo from "../../assets/static/img/whanson-logo.png";
import axios from "axios";
import Loader from "../Loader";
import moment from "moment";
import ReactPaginate from "react-paginate";
import config from "../../config";

const Purchase = ({ verticalTab, name, email, phone }) => {
  useEffect(() => {
    if (verticalTab == 2) {
      onGetData();
    }
  }, [verticalTab]);

  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const onGetData = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem("auth_id"));
    try {
      await axios({
        method: "post",
        url: `${config.apiUrl}/order`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => {
        setData(res.data.reverse());
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const [open, setOpen] = useState(false);
  const [item_details, setItemDetails] = useState([]);
  const subTotal = item_details?.items?.data.reduce(
    (total, obj) => total + parseFloat(obj.vat_free),
    0
  );
  const VatTotal = item_details?.items?.data.reduce(
    (total, obj) => total + parseFloat(obj.vat_free) * 0.2,
    0
  );
  const ShippingTotal = item_details?.items?.data.reduce(
    (total, obj) => total + parseFloat(obj.shipping_cost?.split("£")[1]),
    0
  );

  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = data?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(data?.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data?.length;
    setItemOffset(newOffset);
  };

  return (
    <div>
      {isLoading && <Loader />}
      <div className="ps-page__content">
        <div className="ps-section--account-setting">
          <div className="ps-section__header">
            <h3>Purchase History</h3>
          </div>
          <div className="ps-section__content">
            <table role="table" className="table-striped table">
              <thead className="">
                <tr role="row">
                  <th>Code</th>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Delivery Status</th>
                  <th>Payment Status</th>
                  <th className="text-center">Options</th>
                </tr>
              </thead>
              <tbody className="ant-table-tbody">
                {currentItems?.map((items, i) => {
                  return (
                    <tr role="row" key={i}>
                      <td className="ant-table-cell">PFWH000{items.id}</td>
                      <td>{moment(items.order_date).format("DD-MM-YYYY")}</td>
                      <td>
                        {items.grand_total != null
                          ? "£" + items.grand_total
                          : "-"}
                      </td>
                      <td>{items.delivery_status}</td>
                      <td>
                        <span>
                          <span
                            className={
                              items.payment_status == "paid"
                                ? "ant-tag ant-tag-green"
                                : "ant-tag ant-tag-volcano"
                            }
                          >
                            {items.payment_status == "paid" ? "Paid" : "Unpaid"}
                          </span>
                        </span>
                      </td>
                      <td className="border-botttom-black text-center">
                        <a
                          className="small-btn text-white"
                          onClick={() => {
                            setOpen(true);
                            setItemDetails(items);
                          }}
                        >
                          <i className="icon-eye"></i>
                        </a>
                        {items.invoice_file != null ? (
                          <a
                            className="small-btn-down text-white"
                            target="_blank"
                            href={items.invoice_file}
                          >
                            <i className="icon-download"></i>
                          </a>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="mt-30">
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="Previous"
                renderOnZeroPageCount={null}
                breakClassName={"page-item"}
                breakLinkClassName={"ant-pagination-item-link"}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"ant-pagination-item-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"ant-pagination-item-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"ant-pagination-item-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        title="View Details"
        centered
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={<div className="footer-none"></div>}
        width={800}
      >
        <div>
          {/* <div className="text">

                    </div> */}
          <div className=" bd-text-style">
            <div className="row justify-space-between">
              <div className="col-7">
                <div className="text mb-2">
                  <div style={{ marginTop: "0" }}>
                    <img src={logo} width="120" />
                  </div>
                </div>
                <div>
                  <div className="mb-30">
                    <p className="mb-0">W Hanson (Ironbridge) Ltd</p>
                    <p className="mb-0">
                      92-108 Greenford Road, Harrow, Middlesex, HA1 3QL
                    </p>
                    <p className="mb-0">
                      <span>Email</span> : websales@w-hanson.co.uk
                    </p>
                    <p className="mb-0">
                      <span>Phone </span>: 020 8864 0822
                    </p>
                  </div>
                  <div>
                    <p className="mb-0">Order by</p>
                    <p>Cash Sales</p>
                  </div>
                </div>
              </div>
              <div className="col-5 text-right">
                <div>
                  <div className="m mb-30">
                    <p className="mb-0">
                      <span>Issued By </span>: {name}
                    </p>
                    <p className="mb-0">
                      <span>Order Id </span>: PFWH000{item_details?.id}
                    </p>
                    <p className="mb-0">
                      <span>Order date </span>:{" "}
                      {moment(item_details?.order_date).format("DD-MM-YYYY")}
                    </p>
                  </div>
                  <div className="">
                    <p className="mb-0">Deliver to</p>
                    <p className="mb-0">{name}</p>
                    <p className="mb-0">
                      {item_details?.shipping_address?.address},{" "}
                      {item_details?.shipping_address?.state},{" "}
                      {item_details?.shipping_address?.postal_code},{" "}
                      {item_details?.shipping_address?.country}
                    </p>
                    <p className="mb-0">
                      <span>Email </span>: {email}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-20 mb-30">
              <h4>
                Chain of Custody (COC) certified products are identified in the
                item description.
              </h4>
              <h4>
                <span className="pr-20">FSC CU-COC-805653</span>{" "}
                <span>PEFC CU-PEFC-805653</span>
              </h4>
            </div>
            <table role="table" className="table-striped table">
              <thead className="">
                <tr role="row">
                  <th>Id</th>
                  <th>Description</th>
                  <th>Qty</th>
                  <th>Unit Price</th>
                  <th>VAT</th>
                  <th>Total Price</th>
                </tr>
              </thead>
              <tbody className="ant-table-tbody">
                {item_details?.items?.data?.map((obj, i) => {
                  const unitPrice = parseFloat(obj.vat_free) / obj.quantity;
                  const vatCal = parseFloat(obj.vat_free) * 0.2;
                  return (
                    <tr role="row" key={i}>
                      <td className="ant-table-cell">{obj.id}</td>
                      <td>
                        {obj.product_name}
                        <br />
                        {obj?.width != 0 && obj?.height != 0 ? (
                          <strong class="varition-size">
                            H : {obj?.height}m , W : {obj?.width}m , L :{" "}
                            {obj?.legnth}m{" "}
                          </strong>
                        ) : null}
                      </td>
                      <td>{obj.quantity}</td>
                      <td>
                        £
                        {parseFloat(
                          parseFloat(obj.vat_free) / obj.quantity
                        )?.toFixed(2)}
                      </td>
                      <td>{parseFloat(vatCal).toFixed(2)}</td>
                      <td>
                        £
                        {(
                          parseFloat(obj.vat_free) + parseFloat(vatCal)
                        ).toFixed(2)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <div className="row justify-space-between w-100">
              <div className="col-7 mt-4">
                {item_details?.payment_type == "stripe" ? (
                  <p className="mb-0">
                    <span>Payment Method</span> : Stripe{" "}
                  </p>
                ) : (
                  "*UnPaid"
                )}

                {item_details?.payment_type == "stripe" ? (
                  <p className="mb-0">
                    <span>Transaction Id</span> :{" "}
                    {item_details?.payment_details}
                  </p>
                ) : null}
              </div>
              <div className="bt-main-total row">
                <div className="col-8 pr-0 pl-0">
                  <div className="mb-05">Sub Total</div>
                  <div className="mb-05">Total VAT</div>
                  <div className="btm-line mb-05">Shipping Fee</div>
                  <div className="mb-05 fw-700">Grand Total</div>
                </div>
                <div className="col-4 pl-0 pr-0 text-right">
                  <div className="mb-05">£{subTotal}</div>
                  <div className="mb-05">
                    £{parseFloat(VatTotal)?.toFixed(2)}
                  </div>
                  <div className="btm-line mb-05">
                    £{parseFloat(ShippingTotal).toFixed(2)}
                  </div>
                  <div className="mb-05 fw-700">
                    £
                    {(
                      parseFloat(subTotal) +
                      parseFloat(VatTotal) +
                      parseFloat(ShippingTotal)
                    ).toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Purchase;
